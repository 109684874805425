<template>
  <div
    class="evercam-player w-100"
    :style="{
      height: playerDimensions.height,
      width: playerDimensions.width,
    }"
  >
    <ELayout
      v-global-ref="'playerWrapper'"
      :width="playerDimensions.width"
      :height="playerDimensions.height"
      :with-overlay="withOverlay"
      :is-fullscreen="isFullscreen"
      class="evercam-player__layout w-100"
      :class="{ 'h-100': $device.isIos }"
      @overlay-click="onOverlayClick"
    >
      <template #main="{ size }">
        <v-fade-transition>
          <div
            v-if="
              (isLoading || isFetching) && isJpegEnabled && !isEdgeVideoEnabled
            "
            class="evercam-player__spinner z-index-10 position-absolute h-100 w-100 d-flex justify-center align-center black opacity-40"
          >
            <ESpinner :color="false" size="7xl" opacity="0.6" :dark="true" />
          </div>
        </v-fade-transition>

        <EVideoPlayer
          v-if="isVideoModeEnabled"
          ref="player"
          :key="playerKey"
          class="evercam-player__video-player video-player-box h-100 w-100"
          :height="size.height"
          :width="size.width"
          :sources="sources"
          :video-options="playerOptions"
          :video-listeners="playerListeners"
          :controls="false"
          :is-hls="
            (isVideoStreamEnabled || isEdgeVideoEnabled) && !isWebrtcEnabled
          "
          :is-web-rtc="isWebrtcEnabled"
          :streaming-token="!!streamingToken ? streamingToken : authToken"
          :is-playing="isPlaying"
          :is-zoomable="true"
          :timezone="timezone"
          :with-overlay-on-background="true"
          :zoomable-ignore-pointer-events="false"
          :target-timestamp="videoTargetTimestamp"
          :blur-background="true"
          v-bind="$attrs"
          @poster-error="emitError"
          @hls-error="emitError"
          @hook:mounted="onPlayerMounted"
        >
          <template v-for="(_, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
          <slot></slot>
        </EVideoPlayer>

        <EImagePlayer
          v-else
          ref="player"
          v-model="frameIndex"
          class="evercam-player__image-player"
          :frames="frames"
          :is-playing="isPlaying || isLive"
          :time-per-frame="timePerFrameValue"
          :with-controls="false"
          :aspect-ratio="aspectRatio"
          :height="size.height"
          :width="size.width"
          :isLive="isLive"
          :preload="preload"
          :preload-size="preloadSize"
          :preload-while-playing="preloadWhilePlaying"
          :disable-play-button="disablePlayButton"
          :is-mobile="$device.isMobile"
          :disable-play-pause-animation="disablePlayPauseAnimation"
          :play-on-click="playOnClick"
          :placeholder-image="placeholderImage ?? posterUrl"
          :selected-snapshot-quality="selectedSnapshotQuality"
          :is-annotation-active="isAnnotationActive"
          v-bind="$attrs"
          :is-zoomable="isZoomable"
          @play="isPlaying = true"
          @pause="isPlaying = false"
          @loading="isLoading = $event"
          @hook:beforeDestroy="isLoading = false"
          @preloaded-frames-change="preloadedFrames = $event"
          @change="onImageChange"
          @error="onImageError"
          @timestamp-change="$emit('timestamp-change', $event)"
          @on-image-load="onImageLoad"
          v-on="$listeners"
        >
          <template v-for="(_, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
          <slot></slot>
        </EImagePlayer>
        <slot name="playerOverlay"></slot>
      </template>

      <template #top-right>
        <slot name="top-right"></slot>
      </template>

      <template #bottom-right>
        <slot
          name="bottom-right"
          v-bind="{
            toggleCurrentPlayer,
            isPlaying,
            isLoading,
            image: {
              data: image?.src,
              createdAt: selectedTimestamp,
            },
          }"
        ></slot>
      </template>

      <template #bottom-left>
        <slot
          name="bottom-left"
          v-bind="{
            toggleCurrentPlayer,
            isPlaying,
            isLoading,
            image: {
              data: image?.src,
              createdAt: selectedTimestamp,
            },
          }"
        ></slot>

        <v-fade-transition>
          <EZoomSlider
            v-if="
              !isVideo && withZoomSlider && !withOverlay && !$device.isMobile
            "
            v-model="sliderModel"
            class="evercam-player__zoom-slider e-ml-1"
            vertical
            @zoom-in="zoom(-1)"
            @zoom-out="zoom(1)"
          />
        </v-fade-transition>
      </template>

      <template #overlay="{ size }">
        <slot name="overlay" :size="size"></slot>
      </template>

      <template #top-left>
        <slot name="top-left"></slot>
      </template>

      <template #footer>
        <slot name="footer"></slot>
      </template>

      <template #overlay-footer>
        <div
          v-if="withControls"
          class="player__footer w-100"
          :class="{
            'px-5': $vuetify.breakpoint.smAndUp,
            'px-2': $vuetify.breakpoint.xsOnly,
          }"
          @mouseenter="isFocused = true"
          @mouseleave="isFocused = false"
        >
          <!-- Background -->
          <div class="player__footer__background"></div>

          <!-- ProgressBar -->
          <PlayerProgressBar
            v-if="withPlayerProgressBar"
            :frames="frames"
            :events="matchedSnapshotEvents"
            :frame-index="frameIndex"
            :preloaded-frames="preloadedFrames"
            :timestamp="selectedTimestamp"
            v-bind="$attrs"
            @change="handleStateChange"
            @seek="onUserSelectedNewFrameIndex"
            @frame-index-change="onUserSelectedFrameIndex"
            @marker-active="$emit('marker-active', $event)"
            @marker-reset="$emit('marker-reset', $event)"
            @marker-selected="$emit('marker-selected', $event)"
          >
            <template #tooltip="{ hoveredTimestamp }">
              <EdgeVideoThumbnailPreview
                v-if="isVideo && showBif"
                :nvr-config="nvrConfig"
                :streaming-token="streamingToken"
                :target-timestamp="hoveredTimestamp"
              />
            </template>
          </PlayerProgressBar>

          <!-- Actions -->
          <PlayerActions
            v-if="withPlayerActions"
            :frames="frames"
            :frame-index="frameIndex"
            :time-per-frame="timePerFrameValue"
            :is-playing="isPlaying"
            :info-text="infoText"
            :is-live="isLive"
            :has-live="hasLive && isCameraOnline"
            :is-last-frame="isLastFrame"
            :disable-play-button="
              disablePlayButton || isFetching || snapshots.length === 0
            "
            :initial-snapshot-quality="selectedSnapshotQuality ?? 'auto'"
            :show-snapshot-quality="showSnapshotQuality"
            :is-video="isVideo"
            :has-edge-video="hasEdgeVideo"
            @play="isPlaying = true"
            @pause="isPlaying = false"
            @snapshots-quality="onSnapshotQualityChange"
            @enable-live-view="$emit('enable-live-view')"
            @disable-live-view="$emit('disable-live-view')"
            @change="handleStateChange"
            @frame-index-change="onUserSelectedFrameIndex"
            @toggle-ios-fullscreen="$emit('toggle-ios-fullscreen')"
            @toggle-video-mode="$emit('toggle-video-mode', $event)"
          />
        </div>
      </template>
    </ELayout>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

import PlayerProgressBar from "@evercam/shared/components/imagePlayer/PlayerProgressBar"
import PlayerActions from "@evercam/shared/components/imagePlayer/PlayerActions"
import EdgeVideoThumbnailPreview from "@evercam/shared/components/imagePlayer/EdgeVideoThumbnailPreview"
import { ImageQuality } from "@evercam/shared/types/imagePlayer"
import { CameraStatus } from "@evercam/shared/types/camera"
import {
  matchEventsWithClosestPlayerFrameIndex,
  toQueryString,
} from "@evercam/shared/utils"
import { EvercamApi } from "../api/evercamApi"

import type { Camera, NvrConfig } from "@evercam/shared/types/camera"
import type {
  Snapshot,
  SnapshotRangeRequestPayload,
} from "@evercam/shared/types/recording"
import { AnalyticsEvent, type Timestamp } from "@evercam/shared/types"
import type { GateReportEvent } from "@evercam/shared/types/gateReport"
import type { PropType } from "vue"
import type {
  Frame,
  MatchedSnapshotEvent,
  SnapshotEvent,
  SrcSet,
} from "@evercam/shared/types/imagePlayer"
import { EZoomSlider, inactivityListener } from "@evercam/ui"
export default Vue.extend({
  name: "EvercamPlayer",
  components: {
    PlayerProgressBar,
    PlayerActions,
    EdgeVideoThumbnailPreview,
    EZoomSlider,
  },
  mixins: [inactivityListener],
  props: {
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    nvrConfig: {
      type: Object as PropType<NvrConfig>,
      default: () => ({}),
    },
    selectedTimestamp: {
      type: [String, Date, Number],
      default: "",
    },
    targetTimestamp: {
      type: String,
      default: null,
    },
    start: {
      type: [String, Date, Number],
      default: undefined,
    },
    end: {
      type: [Date, String, Number],
      default: undefined,
    },
    authToken: {
      type: String,
      default: "",
    },
    isEdgeVideo: {
      type: Boolean,
      default: false,
    },
    hasEdgeVideo: {
      type: Boolean,
      default: false,
    },
    hasLive: {
      type: Boolean,
      default: false,
    },
    streamingToken: {
      type: String,
      default: "",
    },
    showBif: {
      type: Boolean,
      default: false,
    },
    snapshotRange: {
      type: Array,
      default: null,
    },
    customRefreshRate: {
      type: [Number],
      default: undefined,
    },
    customSchedule: {
      type: [Array],
      default: undefined,
    },
    schedule: {
      type: [Boolean, String],
      default: false,
    },
    socket: {
      type: Object as PropType<Record<any, any>>,
      default: null,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    //💡: this props will tell us if we are on live view or not <> isLive = !fetchInitialSnapshot
    fetchInitialSnapshots: {
      type: Boolean,
      default: true,
    },
    events: {
      type: Array as PropType<SnapshotEvent<GateReportEvent>[]>,
      default: () => [],
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    initialSnapshotQuality: {
      type: [String, Number],
      default: "auto",
    },
    showSnapshotQuality: {
      type: Boolean,
      default: true,
    },
    timePerFrame: {
      type: Number,
      default: 250,
    },
    preload: {
      type: Boolean,
      default: true,
    },
    preloadSize: {
      type: Number,
      default: 20,
    },
    preloadWhilePlaying: {
      type: Boolean,
      default: true,
    },
    withControls: {
      type: Boolean,
      default: true,
    },
    disablePlayButton: {
      type: Boolean,
      default: false,
    },
    playOnClick: {
      type: Boolean,
      default: true,
    },
    placeholderImage: {
      type: String,
      default: undefined,
    },
    disablePlayPauseAnimation: {
      type: Boolean,
      default: false,
    },
    resetIndexOnFramesChange: {
      type: Boolean,
      default: false,
    },
    count: {
      type: [Number, Boolean],
      default: false,
    },
    fallbackOnEdge: {
      type: Boolean,
      default: false,
    },
    withOverlay: {
      type: Boolean,
      default: false,
    },
    enableVideo: {
      type: Boolean,
      default: true,
    },
    withPlayerActions: {
      type: Boolean,
      default: true,
    },
    withPlayerProgressBar: {
      type: Boolean,
      default: true,
    },
    snapshotsFetcherType: {
      type: String as PropType<"recordings" | "timelapse">,
      default: "recordings",
    },
    withZoomSlider: {
      type: Boolean,
      default: true,
    },
    isZoomable: {
      type: Boolean,
      default: true,
    },
    isAnnotationActive: {
      type: Boolean,
      default: false,
    },
    maxLiveViewSnapshots: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      sliderModel: 1,
      // image player
      snapshots: [] as Snapshot[],
      image: null as HTMLImageElement | null,
      isVideoError: false,
      liveSnapshots: [] as Snapshot[],
      defaultCount: 120,
      isFetching: false,
      frames: [] as Frame[],
      frameIndex: 0,
      infoText: {
        index: "",
        label: "",
      },
      isPlaying: false,
      src: null as string | null,
      userSelectedTimestamp: 0 as string | number,
      isSelectedTimestamp: true,
      preloadedFrames: [],
      preloadingQueueId: 0,
      selectedSnapshotQuality: this.initialSnapshotQuality,
      // image player live view
      lastRenderedImageTimestamp: 0,
      channel: null as Record<string, any> | null,
      isJpegPaused: false,
      recordingsChannel: null as Record<string, any> | null,
      // video player
      bifArrayBuffer: new ArrayBuffer(0),
      isFocused: false,
      videoElement: null as HTMLVideoElement | null,
      isLoading: true,
      timePerFrameValue: this.timePerFrame,
      previousPlayerTime: 0,
      currentTimestamp: this.targetTimestamp as string,
      preloadedSeconds: 0,
      playbackRetries: 0,
      playerKey: 0,
      isSeek: false,
      mediaStream: null as MediaStream | null,
      pc: null as RTCPeerConnection | null,
      restartTimeout: null as number | null,
      eTag: "",
      sessionUrl: "",
      queuedCandidates: [] as any[],
      restartPause: 2000,
      offerData: null as {
        iceUfrag: string
        icePwd: string
        medias: never[]
      } | null,
      isFullscreen: false,
      cacheEventsMatching: false,
      previousEventsTimestamps: [],
    }
  },
  computed: {
    playerDimensions(): { height: string; width: string } {
      if (this.isFullscreen && !this.$device.isIos) {
        return {
          height: `${screen.height}px`,
          width: "100%",
        }
      } else {
        return {
          height: `${this.$attrs.height}px`,
          width: `${this.$attrs.width}px`,
        }
      }
    },
    cameraTimezone(): string {
      return this.camera?.timezone || this.timezone
    },
    isVideo(): boolean {
      if (!this.enableVideo) {
        return false
      }
      if (this.isWebrtcEnabled && this.isLive && !this.isVideoError) {
        return true
      }
      if (this.fetchInitialSnapshots && !this.isLive && !this.isVideoError) {
        return this.isEdgeVideo
      } else {
        return (
          (this.isEdgeVideoEnabled ||
            this.isVideoStreamEnabled ||
            this.isWebrtcEnabled) &&
          !this.isVideoError
        )
      }
    },
    isVideoModeEnabled() {
      return (
        (this.isVideo && !this.isVideoError) ||
        (this.isWebrtcEnabled &&
          this.isLive &&
          this.fetchInitialSnapshots &&
          !this.isVideoError)
      )
    },
    baseWebRtcUrl(): string {
      return `${this.$config.public.mediaMtxWebRtcUrl}/streaming/webrtc`
    },
    webRtcUrl(): string {
      return `${this.baseWebRtcUrl}/${this.camera.id}/whep`
    },
    hlsUrl(): string {
      return this.camera?.proxyUrl?.hls
    },
    isLastFrame(): boolean {
      return this.frameIndex === this.frames.length - 1
    },
    cameraExid(): string {
      return this.camera?.exid || this.camera?.id
    },
    matchedSnapshotEvents(): MatchedSnapshotEvent<GateReportEvent>[] {
      if (this.cacheEventsMatching && this.previouslyMatchedEvents) {
        return this.events.map((e) => {
          const cachedMatchedEvent =
            this.previouslyMatchedEvents.find(
              (event) =>
                (event.id && e.id && event.id === e.id) ||
                (event.tempId && e.tempId && event.tempId === e.tempId)
            ) || {}

          return {
            ...e,
            ...cachedMatchedEvent,
          }
        })
      } else {
        return matchEventsWithClosestPlayerFrameIndex(this.events, this.frames)
      }
    },
    authenticationParams(): Record<string, any> {
      let params = {}
      if (this.authToken && this.fetchInitialSnapshots) {
        params = {
          authorization: this.authToken,
        }
      }

      return params
    },
    requestParams(): Record<string, any> {
      let params: SnapshotRangeRequestPayload = {
        from: this.start as string,
        to: this.end as string,
        schedule: !!this.schedule,
      }

      if (this.count) {
        params.count = this.defaultCount
      }

      if (this.authToken) {
        params = {
          ...params,
          ...this.authenticationParams,
        }
      }

      return params
    },
    isCameraOnline(): boolean {
      return this.camera?.status === CameraStatus.ONLINE
    },
    isCameraWaiting(): boolean {
      return this.camera?.status === CameraStatus.WAITING
    },
    isValidDateInterval(): boolean {
      return (
        this.$moment(this.start).isValid() && this.$moment(this.end).isValid()
      )
    },
    videoTargetTimestamp(): string | number {
      if (this.isLive) {
        return new Date().toISOString()
      } else {
        return this.selectedTimestamp
      }
    },
    isStartofSelectedHour(): boolean {
      const lowerBound = this.snapshots[0]?.createdAt

      return (
        this.$moment(this.selectedTimestamp).format("YYYY-MM-DDTHHZ") ===
          this.$moment(lowerBound).format("YYYY-MM-DDTHHZ") &&
        this.$moment(this.selectedTimestamp).format("mm:ss") === "00:00"
      )
    },
    sources(): Array<{ src: string; type: string }> {
      if (this.isWebrtcEnabled) {
        return [
          {
            src: this.webRtcUrl,
            type: "application/sdp",
          },
        ]
      } else if (this.isVideoStreamEnabled) {
        return [
          {
            src: this.hlsUrl,
            type: "application/x-mpegURL",
          },
        ]
      } else {
        return [
          {
            src: this.edgeStreamingUrl,
            type: "application/x-mpegURL",
          },
        ]
      }
    },
    edgeStreamingUrl(): string {
      const nvrConfig = this.nvrConfig as NvrConfig
      const timestamp = this.targetTimestamp
        ? this.$moment(this.targetTimestamp).format("YYYY-MM-DDTHH:mm:ssZ")
        : ""

      return `${nvrConfig.streamingUrl}?pos=${encodeURIComponent(
        this.fetchInitialSnapshots ? timestamp : ""
      )}&retries=${this.playbackRetries}`
    },
    posterUrl(): string | undefined {
      if (
        (this.isWebrtcEnabled || this.isVideoStreamEnabled) &&
        !this.isVideoError
      ) {
        return
      }

      return (
        this.getSnapshotUrl(this.targetTimestamp as string) ??
        this.snapshotUrl ??
        this.placeholderImage
      )
    },
    playerOptions(): Record<string, any> {
      return {
        muted: true,
        playsinline: true,
        autoplay: this.isPlaying,
        controls: false,
        poster: this.posterUrl,
      }
    },
    playerListeners(): Record<string, any> {
      return {
        timeupdate: this.onTimeUpdate,
        progress: this.onProgress,
        error: this.emitError,
        play: this.onPlay,
        pause: this.onPause,
        canplay: this.onCanPlay,
        loadeddata: this.onLoadedData,
        canplaythrough: this.onCanPlay,
        ended: this.onVideoEnded,
      }
    },
    isWebrtcEnabled(): boolean {
      return (
        this.$permissions.camera?.has.webrtc() &&
        this.isCameraOnline &&
        !this.$device.isIos
      )
    },
    isVideoStreamEnabled(): boolean {
      const isEnabled =
        this.$permissions.camera?.has.videoStream() &&
        this.hlsUrl &&
        !this.isWebrtcEnabled &&
        !this.$device.isFirefox

      this.$emit("is-video-stream-enabled", isEnabled)

      return isEnabled
    },
    isJpegEnabled(): boolean {
      return !this.isVideo
    },
    snapshotUrl(): string {
      if (this.isCameraWaiting) {
        return require("~/assets/img/waiting_for_activation.jpg")
      }

      return this.authToken
        ? `${this.camera?.thumbnailUrl}?authorization=${this.authToken}`
        : this.camera?.thumbnailUrl
    },
    isEdgeVideoEnabled(): boolean {
      const isEnabled = this.isEdgeVideo && !this.isVideoError
      this.$emit("is-edge-video-enabled", isEnabled)

      return isEnabled
    },
    improperWebSocketConnection(): boolean {
      if (!this.camera || !this.socket || this.socket.channels.length === 0) {
        return true
      }

      return !this.socket.endPoint.includes(this.camera?.streamingServer)
    },
    selectedSnapshotIndex() {
      if (
        this.isStartofSelectedHour ||
        !this.selectedTimestamp ||
        !this.isValidDateInterval ||
        ((this.isFetching || this.isLoading) && this.resetIndexOnFramesChange)
      ) {
        return 0
      }
      let targetIndex
      let closestDiff = Infinity
      const targetTimestamp = new Date(this.selectedTimestamp).getTime()
      this.snapshots.forEach((snapshot, index) => {
        const timestamp = snapshot.createdAt
        let diff = Math.abs(new Date(timestamp).getTime() - targetTimestamp)

        if (diff >= 0 && diff < closestDiff) {
          closestDiff = diff
          targetIndex = index
        }
      })

      return targetIndex
    },
    selectedIndex() {
      if (this.isVideo) {
        const selectedTime = this.$moment(this.selectedTimestamp)
        const startTime = this.$moment(this.start)
        const duration = this.$moment.duration(selectedTime.diff(startTime))

        return Math.abs(duration.seconds() + duration.minutes() * 60)
      } else {
        return this.selectedSnapshotIndex
      }
    },
    shouldInitTimestamp() {
      return !this.isVideo && this.isStartofSelectedHour && this.snapshots?.[0]
    },
  },
  watch: {
    requestParams: {
      immediate: true,
      async handler(oldv, v) {
        if (!v || oldv?.from === v?.from) {
          return
        }
        if (!this.isVideo) {
          await this.initSnapshots()
        }
      },
    },
    withOverlay: {
      immediate: true,
      handler(value) {
        if (value) {
          this.isFetching = false
          this.isLoading = false
        }
      },
    },
    isVideoModeEnabled: {
      immediate: true,
      handler(value) {
        this.$emit("video-mode-changed", value)
      },
    },
    isVideo: {
      async handler(value) {
        this.leaveChannel()
        if (this.fetchInitialSnapshots && !this.isWebrtcEnabled) {
          this.isVideoError = false
          this.playbackRetries = 0
        }
        if (!value) {
          if (this.isLive) {
            if (this.improperWebSocketConnection) {
              this.$emit("setup-socket")
            }
            this.$setTimeout(() => {
              this.playJpegStream()
            }, 1000)
          } else {
            this.isPlaying = false
          }
        }
        if (value) {
          const selectedTime = this.$moment(this.selectedTimestamp)
          const startTime = this.$moment(this.start)
          const duration = this.$moment.duration(selectedTime.diff(startTime))

          this.frameIndex = Math.abs(
            duration.seconds() + duration.minutes() * 60
          )
        }
      },
    },
    selectedIndex: {
      handler(val) {
        if (isNaN(val) || val === undefined) {
          return
        }
        this.frameIndex =
          this.isLive && this.frames?.length > 0 ? this.frames.length - 1 : val
      },
    },
    shouldInitTimestamp(value) {
      if (value) {
        this.$emit("timestamp-change", this.snapshots?.[0]?.createdAt)
      }
    },
    // The following avoids recomputing the matched events whenever an event object changes
    // TODO Remove after migrating to Vue 3, and use v-memo instead
    matchedSnapshotEvents(newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
        return
      }
      this.cacheEventsMatching = true
      this.previouslyMatchedEvents = newVal.map((e) => {
        return {
          id: e.id,
          tempId: e.tempId,
          frameIndex: e.frameIndex,
          snapshotTimestamp: e.snapshotTimestamp,
        }
      })
    },
    camera: {
      async handler(newV, oldV) {
        if (newV.id === oldV.id) {
          return
        }
        if (!this.isVideo) {
          this.isLoading = true
        }
        if (!this.hasLive) {
          this.$emit("disable-live-view")
        }
        this.refreshPlayer()
        await this.initSnapshots()
        this.leaveChannel()
        if (this.isLive) {
          if (!this.isVideo) {
            this.$emit("setup-socket")
            this.$setTimeout(() => {
              this.playJpegStream()
            }, 2000)
          }
        } else {
          this.frameIndex = 0
          this.isPlaying = false
        }
      },
    },
    frameIndex: {
      immediate: true,
      handler(index, previousIndex) {
        if (
          (index <= 0 && previousIndex > 0) ||
          index === this.frames.length - 1
        ) {
          this.$emit("snapshot-edge-reached")
        }

        if (index === previousIndex) {
          return
        }
        if (this.isLive && this.frames.length > 0) {
          this.frameIndex = this.frames.length - 1
        }
        if (index && Math.abs(index - previousIndex) > 1) {
          if (!this.withOverlay) {
            this.isLoading = true
          }
        }
      },
    },
    frames: {
      immediate: true,
      handler() {
        if (this.isLive) {
          if (this.frames.length > 0) {
            this.frameIndex = this.frames.length - 1

            return
          } else {
            this.frameIndex = this.frames.length
          }
        }
        if (this.resetIndexOnFramesChange && !this.isVideo) {
          this.frameIndex = 0
        }
        this.cacheEventsMatching = false
      },
    },
    events(newVal) {
      const eventsTimestamps = newVal.map((e) => e.eventTime)
      this.cacheEventsMatching =
        JSON.stringify(this.previousEventsTimestamps) ===
        JSON.stringify(eventsTimestamps)
      this.previousEventsTimestamps = eventsTimestamps
    },
    isLive: {
      immediate: true,
      async handler(value: boolean) {
        this.isJpegPaused = !value
        if (value) {
          this.isLoading = true
          this.frameIndex = Math.max(this.frames.length - 1)
          this.$emit("on-playing-change", true)
          this.isPlaying = true
          if (!this.isVideo) {
            if (this.improperWebSocketConnection) {
              this.$emit("setup-socket")
            }
            this.$setTimeout(() => {
              this.playJpegStream()
            }, 2000)
          } else {
            this.leaveChannel()
          }
        } else {
          if (
            this.isVideo &&
            this.fetchInitialSnapshots &&
            this.isWebrtcEnabled &&
            !this.isVideoError
          ) {
            this.$emit("toggle-video-mode", false)
          }
          this.$emit("disable-live-view")
          this.leaveChannel()
          if (!this.isVideo && !this.withOverlay) {
            if (!this.$attrs.isPlaying) {
              this.isPlaying = false
            } else {
              this.isLoading = true
            }
          }
        }
      },
    },
    currentTimestamp(v) {
      if (this.isVideo) {
        this.onTargetTimestampChange(v)
      }
    },
    selectedTimestamp(t) {
      if (this.fetchInitialSnapshots) {
        this.isVideoError = false
        this.playbackRetries = 0
      }
      const selectedTime = this.$moment(t)
      const userSelectedTime = this.$moment(this.userSelectedTimestamp)
      if (
        Math.abs(selectedTime.diff(userSelectedTime, "hours")) > 1 ||
        selectedTime.hours() !== userSelectedTime.hours()
      ) {
        this.userSelectedTimestamp = this.selectedTimestamp
      }
      if (this.isLive && this.isVideo) {
        const selectedTime = this.$moment(this.selectedTimestamp)
        const startTime = this.$moment(this.start)
        const duration = this.$moment.duration(selectedTime.diff(startTime))

        this.frameIndex = Math.abs(duration.seconds() + duration.minutes() * 60)
      }
      if (this.isVideo) {
        this.onTargetTimestampChange(t)
      }
    },
    async start() {
      if (this.isVideo) {
        const selectedTime = this.$moment(this.selectedTimestamp)
        const startTime = this.$moment(this.start)
        const duration = this.$moment.duration(selectedTime.diff(startTime))

        this.frameIndex = Math.abs(duration.seconds() + duration.minutes() * 60)
      }
    },
    async end() {
      if (this.isVideo) {
        const selectedTime = this.$moment(this.selectedTimestamp)
        const startTime = this.$moment(this.start)
        const duration = this.$moment.duration(selectedTime.diff(startTime))

        this.frameIndex = Math.abs(duration.seconds() + duration.minutes() * 60)
      }
    },
    placeholderImage(src) {
      this.src = src
    },
    isPlaying: {
      immediate: true,
      handler(value) {
        this.$emit("on-playing-change", value)
        if (this.isWebrtcEnabled) {
          if (value) {
            this.scheduleRestart()
          } else {
            this.closeConnection()
          }
        }
      },
    },
    isLoading: {
      immediate: true,
      handler(val) {
        this.$emit("loading", val)
      },
    },
    isFetching: {
      immediate: true,
      handler(value) {
        if (
          !this.isValidDateInterval ||
          (!this.snapshots?.length && !this.isVideo)
        ) {
          if ((this.isFetching || this.isLoading) && this.posterUrl) {
            this.frames = [
              {
                label: "0",
                src: this.posterUrl,
                timestamp: "",
              },
            ]

            return
          }

          if (
            !value &&
            this.isLoading &&
            this.fetchInitialSnapshots &&
            !this.snapshots?.length &&
            !this.isVideo
          ) {
            this.isLoading = false

            this.frames = [
              {
                label: "0",
                src: "/unavailable.jpg",
                timestamp: "",
              },
            ]
          }
        }
      },
    },
  },
  async mounted() {
    await this.initSnapshots()
    this.$nextTick(() => {
      if (this.isVideo) {
        const player = this.$refs?.player as any
        this.videoElement = player?.$refs.player as HTMLVideoElement
      }
    })
    this.$addEventListener("fullscreenchange", this.setFullscreen)
    this.$addEventListener("webkitfullscreenchange", this.setFullscreen)
    this.$addEventListener("mozfullscreenchange", this.setFullscreen)
    this.$addEventListener("MSFullscreenChange", this.setFullscreen)
  },
  beforeDestroy() {
    this.leaveChannel()
    this.closeConnection()
  },
  methods: {
    setFullscreen() {
      if (
        document.fullscreenElement ||
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement
      ) {
        this.isFullscreen = true
      } else {
        this.isFullscreen = false
      }
    },
    onPlayingChange(value: boolean) {
      this.isPlaying = value
    },
    zoom(direction: number) {
      const player = this.$refs.player as any
      const zoomableImage = player.$refs?.zoomableImage
      zoomableImage.zoom(direction)
      this.$root.$emit("analytics-event", {
        eventId:
          direction > 0
            ? AnalyticsEvent.playerZoomOut
            : AnalyticsEvent.playerZoomIn,
        params: { zoomLevel: zoomableImage.zoomLevel },
      })
    },
    onOverlayClick() {
      if (this.playOnClick) {
        this.isPlaying = !this.isPlaying
      }
    },
    // Image Player Methods
    getSnapshotDefaultSrc(createdAt: string | number, queryParams = {}) {
      return `${this.$config.public.apiURL}/cameras/${
        this.cameraExid
      }/recordings/snapshots/${createdAt}?${toQueryString(queryParams)}`
    },
    snapshotToFrame(snapshot: Snapshot): Frame {
      const createdAt = (snapshot?.createdAt || snapshot) as string
      const params = {
        view: true,
        ...this.authenticationParams,
      }

      return {
        label: this.$moment
          .tz(createdAt, this.cameraTimezone)
          .format(
            this.$vuetify.breakpoint.mdAndUp
              ? "DD/MM/YYYY HH:mm:ss"
              : "HH:mm:ss"
          ),
        src: this.getSnapshotDefaultSrc(createdAt, params),
        srcSet: this.getSnapshotSrcSet(createdAt, params),
        timestamp: createdAt as Timestamp,
      }
    },
    getResizedSnapshotUrl(
      quality: ImageQuality,
      createdAt: string | number,
      queryParams = {}
    ) {
      const snapshotUrl = this.getSnapshotDefaultSrc(createdAt, queryParams)

      return this.$imgproxy.getResizedImageUrl(snapshotUrl, quality)
    },
    getSnapshotSrcSet(createdAt: string | number, queryParams = {}): SrcSet {
      return Object.values(ImageQuality).reduce((acc, q) => {
        if (q === ImageQuality.auto) {
          return {
            ...acc,
            [q]: this.getSnapshotDefaultSrc(createdAt, queryParams),
          }
        } else {
          return {
            ...acc,
            [q]: this.getResizedSnapshotUrl(q, createdAt, queryParams),
          }
        }
      }, {}) as SrcSet
    },
    fillFramesGaps(frames: Frame[]) {
      const filledArray = []
      const timestampSet = new Set(
        frames.map((item) => this.$moment(item.timestamp).unix())
      )
      const startTime = this.$moment(this.start)
      let endTime = this.$moment(this.end)
      if (endTime.isAfter(this.$moment())) {
        endTime = this.$moment()
      }
      let currentTimestamp = startTime
      let lastFoundFrame = frames[0] || startTime
      while (currentTimestamp <= endTime) {
        if (timestampSet.has(currentTimestamp)) {
          const frame = frames.find(
            (item) => item.timestamp === currentTimestamp
          )
          filledArray.push({
            ...frame,
            timestamp: this.$moment(frame.timestamp).format(),
          })
        } else {
          filledArray.push({
            timestamp: this.$moment(currentTimestamp).format(),
            label: this.$moment
              .tz(currentTimestamp, this.cameraTimezone)
              .format(
                this.$vuetify.breakpoint.mdAndUp
                  ? "DD/MM/YYYY HH:mm:ss"
                  : "HH:mm:ss"
              ),
            src: lastFoundFrame.src,
          })
        }

        currentTimestamp += 1000
      }

      return filledArray
    },
    async fetchSnapshots(): Promise<Snapshot[] | undefined> {
      if (this.snapshotRange) {
        return this.snapshotRange as Snapshot[]
      }
      let snapshots: Snapshot[] = []
      const isFromParamValid = this.$moment(this.requestParams.from).isValid(),
        isToParamValid = this.$moment(this.requestParams.to).isValid()
      if (!isFromParamValid || !isToParamValid) {
        const invalidParam = !isFromParamValid ? "from" : "to"
        this.$notifications.error({
          text: this.$t("content.invalid_field", {
            target: `${invalidParam} date param`,
          }),
          error: new Error("Invalid snapshots interval dates"),
        })

        return []
      }

      try {
        if (!this.cameraExid) {
          return
        }
        let provider: any
        provider = (EvercamApi as Record<string, any>)[
          this.snapshotsFetcherType
        ].getSnapshotRange
        const response = await provider(this.cameraExid, this.requestParams)
        snapshots = response.snapshots as Snapshot[]
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.fetch_resource_failed", {
            resource: "snapshots",
          }),
          error,
        })
      }

      return snapshots
    },
    reduceArrayToDesiredLength(array: any[] = [], desiredLength: number) {
      let desiredInterval = Math.round(array.length / desiredLength)
      desiredInterval = desiredInterval < 1 ? 1 : desiredInterval

      return array
        .filter((el, index) => index % desiredInterval === 0)
        .slice(0, desiredLength)
    },
    async initSnapshots() {
      if (!this.fetchInitialSnapshots || !this.cameraExid) {
        return
      }
      if (!this.withOverlay) {
        this.isFetching = true
      }
      this.snapshots = []
      this.liveSnapshots = []
      const snapshots = await this.fetchSnapshots()
      if (
        this.count &&
        this.count !== this.defaultCount &&
        typeof this.count === "number"
      ) {
        this.snapshots = this.reduceArrayToDesiredLength(
          snapshots,
          this.count + 1
        )
      } else {
        this.snapshots = snapshots as Snapshot[]
      }
      if (this.fetchInitialSnapshots && this.snapshots.length === 0) {
        this.$root.$emit("set-placeholder-image", this.placeholderImage)
      }
      this.updateFrames()
      this.$emit("snapshots-fetched", this.snapshots)
      this.$setTimeout(() => (this.isFetching = false), 1000)
    },
    toggleCurrentPlayer() {
      if (this.isLive || !this.fetchInitialSnapshots) {
        if (this.isPlaying) {
          this.$emit("disable-live-view")
          this.isPlaying = false
        } else {
          this.isPlaying = true
          this.$emit("enable-live-view")
        }
      } else {
        this.isPlaying = !this.isPlaying
      }
    },
    updateInfoText() {
      let frameCount = ""
      if (!this.isVideo) {
        frameCount = `${Math.min(this.frameIndex + 1, this.frames.length)} / ${
          this.frames.length
        }`
      }

      this.infoText = {
        index: frameCount,
        label: `${
          this.frameIndex < 1
            ? this.frames[this.frameIndex]?.label
            : this.frames[this.frameIndex - 1]?.label || ""
        }`,
      }
    },
    onUserSelectedFrameIndex({ frameIndex }: { frameIndex: number }) {
      this.$emit("disable-live-view")
      this.isSelectedTimestamp = false
      this.userSelectedTimestamp = this.frames[frameIndex].timestamp
      this.frameIndex = frameIndex
      this.$emit("timestamp-change", this.frames[frameIndex].timestamp)
    },
    onImageChange(e: any) {
      this.$emit("image-change", e)
    },
    handleStateChange(change = {}) {
      this.isSelectedTimestamp = false
      Object.entries(change).forEach(([key, value]: [string, any]) => {
        this[key] = value
      })
    },
    onImageLoad(e: any) {
      this.image = e as HTMLImageElement
      if (this.image) {
        this.updateInfoText()
        this.isLoading = false
      }
    },
    onImageError() {
      if (!this.frames.length) {
        return
      }
      if (this.frameIndex > 1 && this.isSelectedTimestamp) {
        return (this.frameIndex -= 1)
      }
      this.isLoading = false
    },
    onSnapshotQualityChange(q: ImageQuality) {
      this.selectedSnapshotQuality = q
      this.$emit("snapshot-quality-change", q)
    },
    onUserSelectedNewFrameIndex() {
      if (!this.isVideo) {
        this.preloadingQueueId += 1

        return
      }
      if (this.$refs && this.$refs.player && this.$refs.player.$refs.player) {
        this.$refs.player.$refs.player.currentTime = 0
      }
    },
    updateFrames() {
      let rawSnapshots = [...this.snapshots, ...this.liveSnapshots]
      const frames = rawSnapshots.map(this.snapshotToFrame) as Frame[]
      if (this.isVideo) {
        this.frames = this.fillFramesGaps(frames)
      } else {
        this.frames = frames
      }
    },
    playJpegStream() {
      if (
        (this.camera?.status !== CameraStatus.ONLINE &&
          this.camera?.status !== CameraStatus.OFFLINE) ||
        (this.isJpegPaused && this.fetchInitialSnapshots)
      ) {
        return
      }
      this.$emit("on-playing-change", true)
      this.socket?.onError((e: any) => {
        console.error(e)
      })
      this.channel = this.socket?.channel(`cameras:${this.camera?.id}`, {})
      this.channel?.join()
      this.socket
        ?.channel(`cameras:${this.camera?.id}`, {})
        ?.on("snapshot-taken", (data) => {
          const src = `data:image/jpeg;base64,${data.image}`
          if (
            this.frames.length >= this.maxLiveViewSnapshots &&
            !this.fetchInitialSnapshots
          ) {
            this.frames.shift()
          }
          this.frames.push({
            timestamp: this.$moment(data.timestamp * 1000).format(),
            src,
            label: this.$moment
              .tz(data.timestamp, this.cameraTimezone)
              .format("DD/MM/YYYY HH:mm:ss"),
          })
          this.frameIndex = this.frames?.length - 1
          if (data.timestamp > this.lastRenderedImageTimestamp) {
            this.src = src
            this.lastRenderedImageTimestamp = data.timestamp

            if (!this.customRefreshRate) {
              return
            }

            this.channel?.leave()
            let isWithinSchedule = true
            let waitTime = this.customRefreshRate
            let [minHour, maxHour] = (this.customSchedule || []) as number[]
            const currentHour = Number.parseInt(
              this.$moment.tz(new Date(), this.cameraTimezone).format("H")
            )

            if (minHour && maxHour) {
              isWithinSchedule =
                currentHour >= minHour && currentHour <= maxHour
            }

            if (!isWithinSchedule) {
              if (minHour < currentHour) {
                minHour += 24
              }
              waitTime = (minHour - currentHour) * 60 * 1000
            }
            this.$setTimeout(() => {
              this.playJpegStream()
            }, waitTime)
          }
        })
      this.subscribeToRecordingsChannel()
    },
    onError(e: any) {
      this.$emit("error", e)
    },
    subscribeToRecordingsChannel() {
      this.recordingsChannel = this.socket?.channel(
        `recordings:${this.cameraExid}`,
        {}
      )
      this.$emit("recordings-channel-initialized", this.recordingsChannel)
      this.recordingsChannel?.join()
      this.recordingsChannel?.on(
        "snapshot-saved",
        ({ timestamp }: { timestamp: number }) => {
          this.$emit(
            "timestamp-change",
            new Date(timestamp * 1000).toISOString()
          )
        }
      )
    },
    leaveChannel() {
      if (!this.channel) {
        return
      }
      this.socket.channels.forEach((channel) => {
        if (this.channel?.topic === channel.topic) {
          channel.leave()
        }
      })
      this.channel.leave()
      this.channel = null
      if (this.recordingsChannel) {
        this.recordingsChannel.leave()
      }
    },
    // WebRtc Player
    webRtcStart() {
      console.log("requesting ICE servers")

      fetch(this.webRtcUrl, {
        method: "OPTIONS",
        headers: new Headers({
          Authorization: `Bearer ${this.authToken}`,
        }),
      })
        .then((res) => {
          this.onIceServers(res)
        })
        .catch((err) => {
          console.log("error: " + err)
          this.scheduleRestart()
        })
    },
    onIceServers(res: any) {
      this.pc = new RTCPeerConnection({
        // @ts-ignore
        iceServers: this.linkToIceServers(res.headers.get("Link")),
      })
      const direction = "sendrecv"
      this.pc.addTransceiver("video", { direction })
      this.pc.addTransceiver("audio", { direction })

      this.pc.onicecandidate = (evt) => this.onLocalCandidate(evt)
      this.pc.oniceconnectionstatechange = () => this.onConnectionState()

      this.pc.ontrack = (evt) => {
        console.log("new track:", evt.track.kind)
        this.mediaStream = evt.streams[0]
        if (!this.$refs.player || !this.$refs.player.$refs.player) {
          return
        }
        this.$refs.player.$refs.player.srcObject = this.mediaStream
        this.$emit("video-loaded", this.$refs.player.$refs.player)
      }

      this.pc.createOffer().then((offer) => this.onLocalOffer(offer))
    },
    unquoteCredential(v: any) {
      return JSON.parse(`"${v}"`)
    },
    linkToIceServers(links: string) {
      if (links !== null) {
        return links.split(", ").map((link) => {
          const m = link.match(
            /^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i
          )
          const ret: Record<string, any> = {
            urls: [m?.[1]],
          }

          if (m?.[3] !== undefined) {
            ret.username = this.unquoteCredential(m[3])
            ret.credential = this.unquoteCredential(m[4])
            ret.credentialType = "password"
          }

          return ret
        })
      } else {
        return []
      }
    },
    onLocalOffer(offer: any) {
      this.offerData = this.parseOffer(offer.sdp)
      this.pc?.setLocalDescription(offer)

      console.log("sending offer")

      fetch(this.webRtcUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/sdp",
          Authorization: `Bearer ${this.authToken}`,
        },
        body: offer.sdp,
      })
        .then((res) => {
          if (res.status !== 201) {
            throw new Error("bad status code")
          }
          this.eTag = res.headers.get("E-Tag") as string
          this.sessionUrl = this.baseWebRtcUrl + res.headers.get("location")

          return res.text()
        })
        .then((sdp) =>
          this.onRemoteAnswer(
            new RTCSessionDescription({
              type: "answer",
              sdp,
            })
          )
        )
        .catch((err) => {
          console.log("error: " + err)
          if (this.playbackRetries < 3) {
            this.scheduleRestart()
          } else {
            this.closeConnection()
            this.$emit("toggle-video-mode", false)
          }
          this.emitError(err)
        })
    },
    onConnectionState() {
      if (this.restartTimeout !== null) {
        return
      }

      console.log("peer connection state:", this.pc?.iceConnectionState)

      switch (this.pc?.iceConnectionState) {
        case "disconnected":
          this.$emit("is-loading", true)
          this.scheduleRestart()
          break
        case "checking":
          this.$emit("is-loading", true)
          break
      }
    },
    onRemoteAnswer(answer: any) {
      if (this.restartTimeout !== null) {
        return
      }

      this.pc?.setRemoteDescription(new RTCSessionDescription(answer))

      if (this.queuedCandidates.length !== 0) {
        this.sendLocalCandidates(this.queuedCandidates)
        this.queuedCandidates = []
      }
    },
    onLocalCandidate(evt: any) {
      if (this.restartTimeout !== null) {
        return
      }

      if (evt.candidate !== null) {
        if (this.eTag === "") {
          this.queuedCandidates.push(evt.candidate)
        } else {
          this.sendLocalCandidates([evt.candidate])
        }
      }
    },
    sendLocalCandidates(candidates: any[]) {
      fetch(this.sessionUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/trickle-ice-sdpfrag",
          "If-Match": this.eTag,
          Authorization: `Bearer ${this.authToken}`,
        },
        body: this.generateSdpFragment(this.offerData, candidates),
      })
        .then((res) => {
          if (res.status !== 204) {
            throw new Error("bad status code")
          }
        })
        .catch((err) => {
          console.log("error: " + err)
          this.scheduleRestart()
        })
    },
    scheduleRestart() {
      if (this.restartTimeout !== null) {
        return
      }
      this.$emit("is-loading", true)

      this.closeConnection()

      this.restartTimeout = window.setTimeout(() => {
        this.restartTimeout = null
        this.webRtcStart()
      }, this.restartPause)
      this.eTag = ""
      this.queuedCandidates = []
    },
    closeConnection() {
      if (this.pc !== null) {
        this.pc.close()
        this.pc = null
      }
    },
    parseOffer(offer: any) {
      const ret = {
        iceUfrag: "",
        icePwd: "",
        medias: [] as any[],
      }

      for (const line of offer.split("\r\n")) {
        if (line.startsWith("m=")) {
          ret.medias.push(line.slice("m=".length))
        } else if (ret.iceUfrag === "" && line.startsWith("a=ice-ufrag:")) {
          ret.iceUfrag = line.slice("a=ice-ufrag:".length)
        } else if (ret.icePwd === "" && line.startsWith("a=ice-pwd:")) {
          ret.icePwd = line.slice("a=ice-pwd:".length)
        }
      }

      return ret
    },
    generateSdpFragment(offerData, candidates) {
      const candidatesByMedia = {}
      for (const candidate of candidates) {
        const mid = candidate.sdpMLineIndex
        if (candidatesByMedia[mid] === undefined) {
          candidatesByMedia[mid] = []
        }
        candidatesByMedia[mid].push(candidate)
      }

      let frag =
        "a=ice-ufrag:" +
        offerData.iceUfrag +
        "\r\n" +
        "a=ice-pwd:" +
        offerData.icePwd +
        "\r\n"

      let mid = 0

      for (const media of offerData.medias) {
        if (candidatesByMedia[mid] !== undefined) {
          frag += "m=" + media + "\r\n" + "a=mid:" + mid + "\r\n"

          for (const candidate of candidatesByMedia[mid]) {
            frag += "a=" + candidate.candidate + "\r\n"
          }
        }
        mid++
      }

      return frag
    },
    // Video Player Methods
    onPlayerMounted() {
      this.videoElement = this.$refs?.player?.$refs?.player
      this.$emit("video-mounted", this.$refs?.player?.$refs?.player)
    },
    play(player?: HTMLVideoElement) {
      if (player) {
        player.play()
      } else {
        if (!this.videoElement) {
          this.$emit("toggle-video-mode", false)

          return
        }
        this.videoElement?.play()
      }
    },
    pause(player?: HTMLVideoElement) {
      if (player) {
        player.pause()
      } else {
        if (!this.videoElement) {
          this.$emit("toggle-video-mode", false)

          return
        }
        this.videoElement?.pause()
      }
    },
    onLoadedData() {
      if (this.isPlaying) {
        this.videoElement?.play()
      }
      this.$emit("video-loaded", this.videoElement)
    },
    onCanPlay() {
      this.isLoading = false
      if (this.isPlaying) {
        this.videoElement?.play()
      }
    },
    onPlay() {
      this.$emit("play")
      this.previousPlayerTime = this.videoElement?.currentTime ?? 0
    },
    onPause() {
      this.$emit("pause")
    },
    onTimeUpdate() {
      if (!this.videoElement) {
        return
      }
      const currentPlayerTime = this.videoElement?.currentTime

      if (
        (this.previousPlayerTime === 0 && currentPlayerTime > 1) ||
        this.isSeek
      ) {
        this.previousPlayerTime = currentPlayerTime
        this.isSeek = false

        return
      }
      this.currentTimestamp = this.$moment(this.targetTimestamp)
        .tz(this.cameraTimezone)
        .add(Math.floor(currentPlayerTime), "seconds")
        .format("YYYY-MM-DDTHH:mm:ssZ")
      this.frameIndex = this.frames.findIndex(
        (frame) => frame.timestamp === this.currentTimestamp
      )
      this.previousPlayerTime = currentPlayerTime
      this.$emit("timestamp-change", this.currentTimestamp)
    },
    onTargetTimestampChange(timestamp: string) {
      if (!this.videoElement) {
        return
      }
      const diffSeconds = this.$moment(timestamp).diff(
        this.$moment(this.currentTimestamp),
        "seconds"
      )

      if (diffSeconds === 0) {
        return
      }
      const playerTime = this.videoElement?.currentTime
      const newVideoTime = playerTime + diffSeconds
      const totalVideoTime = this.videoElement?.duration ?? 0
      if (newVideoTime < 0 || newVideoTime > totalVideoTime) {
        this.refreshPlayer()
      } else {
        if (this.videoElement && isFinite(newVideoTime)) {
          this.videoElement.currentTime = newVideoTime
        }
        this.currentTimestamp = timestamp
        this.frameIndex = this.frames.findIndex(
          (frame) => frame.timestamp === timestamp
        )
        this.isSeek = true
      }
    },
    onVideoEnded() {
      this.$emit("video-ended")
    },
    refreshPlayer() {
      this.playerKey++
      this.play()
    },
    onProgress() {
      if (!this.videoElement?.buffered.length) {
        return
      }
      try {
        this.preloadedSeconds = this.videoElement.buffered.end(
          this.videoElement.buffered.length - 1
        )
      } catch (e) {
        console.error(e)
      }
    },
    getCurrentPlayerRef() {
      if (this.isJpegEnabled) {
        return
      }

      return this.$refs?.player?.$refs?.videoPlayer?.$refs.player
    },
    emitError(e: any) {
      this.$emit("video-error", e)
      if (this.playbackRetries >= 3) {
        this.isVideoError = true
        if (!this.isLive && this.isVideo) {
          this.frameIndex = 0
        }
        this.$emit("toggle-video-mode", false)

        return
      }

      if (e.type === "networkError" || e.type === "mediaError") {
        this.refreshPlayer()
      }
      this.playbackRetries += 1
    },
    getSnapshotUrl(timestamp: string): string | undefined {
      if (this.isVideoStreamEnabled) {
        return ""
      }

      let formattedTimestamp = ""
      if (timestamp) {
        formattedTimestamp = this.$moment
          .utc(timestamp)
          .format("YYYY-MM-DDTHH:mm:ss[Z]")
      }
      if (this.isEdgeVideoEnabled) {
        return `${
          (this.nvrConfig as NvrConfig).snapshotUrl
        }?access_token=${encodeURIComponent(
          this.streamingToken
        )}&time=${encodeURIComponent(formattedTimestamp)}`
      }
    },
  },
})
</script>

<style>
.player__footer {
  position: relative !important;
}
</style>
